var Site = {
	admin: false,
	init: function () {
		var _ = this;

		if (typeof siteAdmin != 'undefined' && siteAdmin)
			_.admin = true;

		_.lazyload();
		_.onResize();
		_.menu();
		Sound.init();
		Home.init();
		Project.init();
		Agence.init();
		Contact.init();
		Login.init();
	},
	onResize: function () {
		var _ = this;

		$(window).on('resize', function () {
			_.imageRatio();
		});
	},
	lazyload: function () {
		$('img[data-lazyload="1"]').lazyload({
			event: 'scroll',
			threshold: 200,
			effect: 'fadeIn'
		});
	},
	imageRatio: function () {
		if (Site.admin && typeof siteAdminAsVisitor != 'undefined' && !siteAdminAsVisitor)
			return;

		var _ = this;

		if (!window.matchMedia("(min-width: 768px)").matches && !(Site.admin && (typeof siteAdminAsVisitor == 'undefined' || !siteAdminAsVisitor))) {
			$('img[data-keep-ratio="1"]').each(function () {
				var $this = $(this);
				$this[0].style.width = '';
				$this[0].style.height = '';
			});
		} else {
			var minHeight = 0;
			$('img[data-keep-ratio="1"]').each(function () {
				var $this = $(this);

				$this[0].style.width = '';
				$this[0].style.height = '';

				var ratio = parseInt($this.attr('width')) / parseInt($this.attr('height'));
				var h = $this.parent().width() / ratio;

				if (!minHeight)
					minHeight = h;
				else
					minHeight = Math.min(minHeight, h);
			});

			var ratio = 0.7;
			if (typeof screen.orientation != 'undefined') {
				if (screen.orientation.angle == 90 || screen.orientation.angle == -90)
					ratio = 1;
			}

			minHeight = Math.min(minHeight, $(window).height() * ratio);

			$('img[data-keep-ratio="1"]').each(function () {
				var $this = $(this);

				$this[0].style.width = '';
				$this[0].style.height = '';

				var ratio = parseInt($this.attr('width')) / parseInt($this.attr('height'));

				$this[0].style.width = minHeight * ratio + 'px';
				$this[0].style.height = minHeight + 'px';

				// $this.parent().css({
				// 	width: minHeight * ratio,
				// 	height: minHeight
				// });
			});
		}
	},
	menu: function () {
		$('header .menuButton').click(function () {
			$(this).toggleClass('open');
			$('header nav').toggleClass('open');
		});
	}
};

const audioElement = new Audio('/medias/music/projets.mp3');
var Sound = {
	isPlaying: false,
	init: function () {
		var _ = this;

		Modernizr.on('videoautoplay', function (result) {
			if (result) {
				$('html .soundButton').bind('click', Sound.togglePlay)
					.attr('title', 'Mettre en pause la musique');
				if (!Sound.isPlaying && Cookies.get('musiqueNoAutoplay') != 1) {
					Sound.isPlaying = true;
					audioElement.play();
				}
			} else {
				$('html .soundButton').addClass('trigger').bind('click', Sound.mobileHandler)
					.attr('title', 'Activer la musique');
			}
		});
	},
	mobileHandler: function () {
		$('html .soundButton.trigger').removeClass('trigger');
		$('html .soundButton').unbind('click', Sound.mobileHandler)
			.unbind('click', Sound.mobileTrigger)
			.bind('click', Sound.togglePlay)
			.attr('title', 'Lancer la lecture de la musique');
		if (!Sound.isPlaying)
			audioElement.play();
	},
	togglePlay: function (event) {
		var _ = Sound;
		if (!_.isPlaying) {
			_.isPlaying = true;
			audioElement.play();
			$(event.target).attr('title', 'Mettre en pause la musique').removeClass('paused');
			Cookies.remove('musiqueNoAutoplay');
		} else {
			_.isPlaying = false;
			audioElement.pause();
			$(event.target).attr('title', 'Lancer la lecture de la musique').addClass('paused');
			Cookies.set('musiqueNoAutoplay', 1, 365);
		}
	}
};

var Cookies = {
	set: function (cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	},
	get: function (cname) {
		var name = cname + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ')
				c = c.substring(1);
			if (c.indexOf(name) == 0)
				return c.substring(name.length, c.length);
		}
		return '';
	},
	remove: function (cname) {
		document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
};

var Home = {
	logo: null,
	init: function () {
		var _ = this;

		if (!$('body').hasClass('home'))
			return false;

		$(document).on('cycle-post-initialize', function () {
			Site.imageRatio();
		});

		_.setAnimatedElements();
		_.animate();
	},
	setAnimatedElements: function () {
		var _ = this;
		var header = $('.home header');
		var logo = $('.home .logo');
		var textH1 = $('.home .wrapper h1');
		var textSub = $('.home .wrapper .sub');

		$('.home').addClass('solo');
		$('.home header .center .wrapper .actions, .home header .center nav').hide();

		_.logo = new TimelineMax();
		_.logo
			.set(header, {
				top: '50%',
				width: '100%',
				marginTop: -69
			})
			.set(logo, {
				opacity: 0
			})
			.set(textH1, {
				opacity: 0
			})
			.set(textSub, {
				opacity: 0
			})
			.to(logo, 1, {
				delay: 0.25,
				opacity: 1
			})
			.to(textH1, 1, {
				delay: 0.5,
				opacity: 1
			})
			.to(textSub, 1, {
				delay: 0.5,
				opacity: 1
			})
			.to(header, 1, {
				delay: 0.5,
				top: 0,
				height: 'auto',
				marginTop: 0,
				ease: Linear.easeNone,
				onComplete: function () {
					$('.home').removeClass('solo');
					$('.home header .center .wrapper .actions, .home header .center nav').fadeIn();
					location = "/agence/";
				}
			})
			.pause();
	},
	animate: function () {
		var _ = this;
		_.logo.play();
	}
};

var Project = {
	init: function () {
		var _ = this;

		if (!$('body').hasClass('projects'))
			return false;

		if (!Site.admin) {
			$(document).on('cycle-post-initialize', function () {
				Site.imageRatio();
			});
			$('.image-slider').cycle();
			// $(window).on('orientationchange', function () {
			// 	location.reload();
			// });
		}
		if (Site.admin && (typeof siteAdminAsVisitor == 'undefined' || !siteAdminAsVisitor))
			Site.imageRatio();

		$('.backButton').click(function () {
			if (typeof siteAdmin != 'undefined' && siteAdmin)
				location = '/realisations#project-' + $(this).data('project-id');
			else
				Turbolinks.visit('/realisations#project-' + $(this).data('project-id'));
			// $([document.documentElement, document.body]).animate({
			//        scrollTop: $("#project-" + $(this).data('project-id')).offset().top
			//    }, 10);
		});
	}
};

var Agence = {
	init: function () {
		var _ = this;

		if (!$('body').hasClass('agence'))
			return false;

		Site.imageRatio();
	}
};

var Contact = {
	init: function () {
		var _ = this;

		if (!$('body').hasClass('contact'))
			return false;

		$('#form_firstname').removeAttr('required');

		$('.text .email a').click(_.ml);
	},
	ml: function () {
		window.open('\x6D\x61\x69\x6C\x74\x6F\x3A' + $(this).text(), '_self');
		return false;
	}
};

var Login = {
	init: function () {
		var _ = this;

		if (!$('body').hasClass('admin'))
			return false;

		$('.content form').on('submit', _.submit);
		$('input[type="submit"]').show().prop('disabled', 0);
	},
	submit: function () {
		var token = $('input[name="login[token]"]').val();

		if (!token)
			return true;

		if ($('input[name="login[user]"]').val() && $('input[type="password"]').val())
			$('input[name="login[token]"]').val('$1$' + $.md5(token + $.md5($('input[type="password"]').val())));
		$('input[type="password"]').val('');

		return true;
	}
};


$(document).ready(function () {
	if ((typeof Turbolinks != 'undefined' && Turbolinks.supported) && (typeof siteAdmin == 'undefined' || !siteAdmin)) {
		document.addEventListener('turbolinks:load', function () {
			Site.init();
			// if (Site.admin)
			Site.imageRatio();

			if (location.hash && $(location.hash).length > 0) {
				$([document.documentElement, document.body]).animate({
					scrollTop: $(location.hash).offset().top - parseInt($('main section').css('padding-top'))
				}, 10);
			}
		});
	} else {
		Site.init();
	}
});

$(window).load(function () {
	// if (Site.admin && (typeof siteAdminAsVisitor == 'undefined' || !siteAdminAsVisitor))
	Site.imageRatio();
});